import { Link } from 'react-router-dom';

const Notifications = () => {
    return (
        <div className="container">
            <a>TODO</a>
        </div>
    );
};

export default Notifications;
