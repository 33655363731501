import React, { useState } from 'react';

const NextMatchTable = ({ nextMatches, onDelete, onEdit }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);

  // Form data for editing
  const [formData, setFormData] = useState({
    id: '',
    date: '',
    matchHours: '',
    departureBusHours: '',
    departurePlaceBus: '',
    arrivalPlaceBus: '',
    paymentLink: '',
    vsTeam: '',
  });

  // Handle delete click
  const handleDeleteClick = (id) => {
    setSelectedMatchId(id);
    setShowDialog(true); // Show the dialog when delete is clicked
  };

  // Confirm delete
  const confirmDelete = () => {
    onDelete(selectedMatchId); // Call the delete function from parent
    setShowDialog(false); // Close the dialog
  };

  // Cancel delete
  const cancelDelete = () => {
    setShowDialog(false); // Close the dialog without deleting
  };

  // Handle edit click
  const handleEditClick = (match) => {
    setSelectedMatch(match);
    setFormData({ ...match }); // Pre-fill the form with match data
    setEditMode(true); // Enable edit mode
  };

  // Handle input changes for the edit form
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Handle form submission for edit
  const handleEditSubmit = async (event) => {
    event.preventDefault();
    await onEdit(formData); // Call the edit function from parent
    setEditMode(false); // Close the form after submitting
    setSelectedMatch(null); // Clear selected match
  };

  return (
    <>
      {/* Table */}
      {!editMode ? (
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>VS</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Hora de salida Micro</th>
              <th>Lugar de salida Micro</th>
              <th>Lugar de llegada Micro</th>
              <th>Link de pago</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {nextMatches.map((match) => (
              <tr key={match.id}>
                <td>{match.vsTeam}</td>
                <td>{match.date}</td>
                <td>{match.matchHours}</td>
                <td>{match.departureBusHours}</td>
                <td>{match.departurePlaceBus}</td>
                <td>{match.arrivalPlaceBus}</td>
                <td>{match.paymentLink}</td>
                <td>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <button
                      onClick={() => handleEditClick(match)}
                      className="btn btn-warning"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => handleDeleteClick(match.id)}
                      className="btn btn-danger"
                    >
                      Eliminar
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        /* Edit form */
        <div className="edit-form">
          <h3>Editar Partido</h3>
          <form onSubmit={handleEditSubmit}>
            <div className="form-group">
              <label>VS Team</label>
              <input
                type="text"
                name="vsTeam"
                value={formData.vsTeam}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Date</label>
              <input
                type="text"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Match Hours</label>
              <input
                type="text"
                name="matchHours"
                value={formData.matchHours}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Departure Bus Hours</label>
              <input
                type="text"
                name="departureBusHours"
                value={formData.departureBusHours}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Departure Place Bus</label>
              <input
                type="text"
                name="departurePlaceBus"
                value={formData.departurePlaceBus}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Arrival Place Bus</label>
              <input
                type="text"
                name="arrivalPlaceBus"
                value={formData.arrivalPlaceBus}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Payment Link</label>
              <input
                type="text"
                name="paymentLink"
                value={formData.paymentLink}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div style={{ height: '20px' }}></div>

            <button
              type="submit"
              style={{ backgroundColor: '#C73B33', borderColor: '#FF5733', color: '#FFF' }}
              className="btn">
              Guardar Cambios
            </button>


            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={() => setEditMode(false)}>
              Cancelar
            </button>

            <div style={{ height: '80px' }}></div>

          </form>
        </div>
      )}

      {/* Dialog box for delete confirmation */}
      {showDialog && (
        <div className="modal" style={modalStyles}>
          <div className="modal-content" style={modalContentStyles}>
            <h4>Estas seguro?</h4>
            <p>Una vez borrado no se puede recuperar</p>
            <div className="modal-footer">
              <button onClick={confirmDelete} className="btn btn-danger">
                OK
              </button>
              <button onClick={cancelDelete} className="btn btn-secondary">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Modal styles for delete confirmation dialog
const modalStyles = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyles = {
  backgroundColor: 'white',
  padding: '50px',
  borderRadius: '5px',
  textAlign: 'center',
};

export default NextMatchTable;
