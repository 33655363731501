import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import NextMatch from './tabs/NextMatch/NextMatch';
import Announcements from './tabs/Announcements/Announcements';
import Notifications from './tabs/Notifications/Notifications';
import Navbar from './navbar/Navbar';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/next-match" element={<NextMatch />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/notifications" element={<Notifications />} />
      </Routes>
    </Router>
  );
};

export default App;