import api from '../../api';
import React, { useState, useEffect } from 'react';
import NextMatchForm from './NextMatchForm';
import NextMatchTable from './NextMatchTable';

const NextMatch = () => {
  const [nextMatches, setNextMatches] = useState([]);

  const [formDataNextMatch, setFormDataNextMatch] = useState({
    date: '',
    matchHours: '',
    departureBusHours: '',
    departurePlaceBus: '',
    arrivalPlaceBus: '',
    paymentLink: '',
    vsTeam: '',
  });

  //#region Fetch

  const fetchNextMatches = async () => {
    const response = await api.get('/nextMatch/');
    setNextMatches(response.data);
  };

  useEffect(() => {
    fetchNextMatches();
  }, []);

  //#endregion Fetch

  //#region Delete 

  const handleDelete = async (id) => {
    try {
      await api.delete(`/nextMatch/${id}`);
      fetchNextMatches();
    } catch (error) {
      console.error('Error deleting match:', error);
    }
  };

  //#endregion Delete 

  //#region Edit

  const handleEdit = async (updatedMatch) => {
    try {
      await api.put(`/nextMatch/${updatedMatch.id}`, updatedMatch);
      fetchNextMatches(); // Refresh the list after successful edit
    } catch (error) {
      console.error('Error editing match:', error);
    }
  };

  //#endregion Edit

  //#region HandleInput Form 

  const handleInputChangeNextMatch = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormDataNextMatch({
      ...formDataNextMatch,
      [event.target.name]: value,
    });
  };

  const handleFormNextMatchSubmit = async (event) => {
    event.preventDefault();
    await api.post('/nextMatch/', formDataNextMatch);
    fetchNextMatches();
    setFormDataNextMatch({
      date: '',
      matchHours: '',
      departureBusHours: '',
      departurePlaceBus: '',
      arrivalPlaceBus: '',
      paymentLink: '',
      vsTeam: '',
    });
  };

  //#endregion HandleInput Form 

  return (
    <div className="container">
      <NextMatchForm
        formDataNextMatch={formDataNextMatch}
        onInputChange={handleInputChangeNextMatch}
        onSubmit={handleFormNextMatchSubmit}
      />
      <div style={{ height: '80px' }}></div>
      <NextMatchTable
        nextMatches={nextMatches}
        onDelete={handleDelete}
        onEdit={handleEdit}  
      />
    </div>
  );
};

export default NextMatch;
