import React from 'react';

const PostForm = ({ formDataPost, onInputChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="mb-3 mt-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input type="text" className="form-control" id="name" name="name" onChange={onInputChange} value={formDataPost.name} />
      </div>

      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description
        </label>
        <input type="text" className="form-control" id="description" name="description" onChange={onInputChange} value={formDataPost.description} />
      </div>

      <div className="mb-3">
        <label htmlFor="date" className="form-label">
          Fecha
        </label>
        <input type="text" className="form-control" id="date" name="date" onChange={onInputChange} value={formDataPost.date} />
      </div>

      <div className="mb-3">
        <label htmlFor="link" className="form-label">
          Link
        </label>
        <input type="text" className="form-control" id="link" name="link" onChange={onInputChange} value={formDataPost.link} />
      </div>

      <button type="submit" className="btn btn-primary">
        Crear
      </button>
    </form>
  );
};

export default PostForm;
