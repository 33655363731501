import React from 'react';

const NextMatchForm = ({ formDataNextMatch, onInputChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="mb-3 mt-3">
        <label htmlFor="vsTeam" className="form-label">
          Versus
        </label>
        <input type="text" className="form-control" id="vsTeam" name="vsTeam" onChange={onInputChange} value={formDataNextMatch.vsTeam} />
      </div>

      <div className="mb-3 mt-3">
        <label htmlFor="date" className="form-label">
          Fecha
        </label>
        <input type="text" className="form-control" id="date" name="date" onChange={onInputChange} value={formDataNextMatch.date} />
      </div>

      <div className="mb-3">
        <label htmlFor="matchHours" className="form-label">
          Hora
        </label>
        <input type="text" className="form-control" id="matchHours" name="matchHours" onChange={onInputChange} value={formDataNextMatch.matchHours} />
      </div>

      <div className="mb-3">
        <label htmlFor="departureBusHours" className="form-label">
          Hora Salida Micro
        </label>
        <input type="text" className="form-control" id="departureBusHours" name="departureBusHours" onChange={onInputChange} value={formDataNextMatch.departureBusHours} />
      </div>

      <div className="mb-3">
        <label htmlFor="departurePlaceBus" className="form-label">
          Lugar Salida Micro
        </label>
        <input type="text" className="form-control" id="departurePlaceBus" name="departurePlaceBus" onChange={onInputChange} value={formDataNextMatch.departurePlaceBus} />
      </div>

      <div className="mb-3">
        <label htmlFor="paymentLink" className="form-label">
          Link de Pago Micro
        </label>
        <input type="text" className="form-control" id="paymentLink" name="paymentLink" onChange={onInputChange} value={formDataNextMatch.paymentLink} />
      </div>

      <div className="mb-3">
        <label htmlFor="arrivalPlaceBus" className="form-label">
          Lugar de Llegada Micro
        </label>
        <input type="text" className="form-control" id="arrivalPlaceBus" name="arrivalPlaceBus" onChange={onInputChange} value={formDataNextMatch.arrivalPlaceBus} />
      </div>
      <button
        type="submit"
        style={{ backgroundColor: '#C73B33', borderColor: '#FF5733', color: '#FFF' }}
        className="btn">
        Crear Próximo Partido
      </button>
    </form>
  );
};


export default NextMatchForm;