import api from '../../api';
import React, { useState, useEffect } from 'react'
import PostForm from './PostForm';
import PostTable from './PostTable';

const Announcements = () => {
  const [posts, setPosts] = useState([]);

  const [formDataPost, setFormDataPost] = useState({
    name: '',
    description: '',
    date: '',
    link: '',
    is_new: false
  });

  const fetchPosts = async () => {
    const response = await api.get('/posts/');
    setPosts(response.data)
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormDataPost({
      ...formDataPost,
      [event.target.name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    await api.post('/posts/', formDataPost);
    fetchPosts();
    setFormDataPost({
      name: '',
      description: '',
      date: '',
      link: '',
      is_new: false
    });
  };

  return (
    <div className="container">
      <PostForm formDataPost={formDataPost} onInputChange={handleInputChange} onSubmit={handleFormSubmit} />
      <PostTable posts={posts} />
    </div>
  );
};

export default Announcements;
